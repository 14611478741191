/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stats} from 'components/case-study/dam';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Summary, Citation, Figure, Caption, Meta, MetaField} = _components;
  if (!Caption) _missingMdxReference("Caption", true);
  if (!Citation) _missingMdxReference("Citation", true);
  if (!Figure) _missingMdxReference("Figure", true);
  if (!Meta) _missingMdxReference("Meta", true);
  if (!MetaField) _missingMdxReference("MetaField", true);
  if (!Summary) _missingMdxReference("Summary", true);
  return React.createElement(React.Fragment, null, React.createElement(Summary, null, "The Diplome avant la Médaille is a nonprofit organization that needs to pair hundreds of students with volunteers according to their availabilities and preferences. They came to us to get a program to automatically obtain a great pairing solution. Here's how we've helped."), "\n", React.createElement(_components.p, null, "At our coworking space, we frequently meet inspiring organizations. One of them is the ", React.createElement(_components.a, {
    href: "https://diplomeavantlamedaille.org"
  }, "Diplôme avant la médaille"), " nonprofit organization. Since its creation in 2012, it is making a huge difference in the fight against school dropouts in Quebec City: 95% of their students-atheletes have been able to pass their school year while 92% of the teachers witness academic improvement! Their secret is to promote sports in order to help students focus in class. It is normal if this reminds you of Coach Carter, it is a great source of inspiration for the organization."), "\n", React.createElement(_components.p, null, "An important facet of their program is the tutoring. Students meet volunteers once a week for study sessions. In 2019, 110 students and tutors were engaged in the program. And the number of inscriptions is expected to grow each year."), "\n", React.createElement(Stats, {
    title: "2019-2020 school year",
    stats: [{
      unit: '%',
      quantity: 95,
      description: "Students participating in the program that have been able to pass their year"
    }, {
      quantity: 197,
      description: "Supported students in 2019-2020. It sums up to 400 supported students since 2012."
    }, {
      unit: '%',
      quantity: 92,
      description: "Teachers witnessing academic improvement"
    }]
  }), "\n", React.createElement(_components.p, null, "The success of DAM comes with the practical problem of pairing tutors and students. Until now, they matched people by hand on a large bulletin board. It takes days and human resources that could be better used elsewhere, and the result is probably not the best possible matching solution with respect to people's preferences (anyway, how can you verify it without a computer?). With the growth of the number of inscriptions, this manual pairing becomes impractical for the coming years."), "\n", React.createElement(_components.p, null, "Pairing is a notoriously hard task, ask airlines companies, and can be framed as an integer constraint problem, also called a maximum weight matching or the assignment problem. Let say we have N students and N tutors. You can define N2 unique pairs of students and tutors. Each pair has a score characterizing how much the student's and tutor's preferences (subject, availability, and schools) are alike. The optimization task consists in selecting the most number of pairs, at most N, but verifying that each tutor and student are selected only once and maximizing the scores over the selected pairs."), "\n", React.createElement(Citation, null, "The success of the project comes with the practical problem of pairing tutors and students."), "\n", React.createElement(_components.p, null, "For instance, there is 100 possible pairs and over 3 million possible pairing solutions for only 10 students and 10 tutors (the number of solutions grows as the factorial of the number of students). Even for that number of students, the outcome of a manual pairing will most likely be a low quality solution. We must rely on computers to deal with such complexity and find valuable pairing solutions. This is where Hectiq can help."), "\n", React.createElement(Figure, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 1200px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 58.00000000000001%; position: relative; bottom: 0; left: 0; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"DAM\"\n        title=\"DAM\"\n        src=\"/static/edb8780061220c2f05fff8f22dc99ade/c1b63/dam.png\"\n        srcset=\"/static/edb8780061220c2f05fff8f22dc99ade/5a46d/dam.png 300w,\n/static/edb8780061220c2f05fff8f22dc99ade/0a47e/dam.png 600w,\n/static/edb8780061220c2f05fff8f22dc99ade/c1b63/dam.png 1200w,\n/static/edb8780061220c2f05fff8f22dc99ade/d61c2/dam.png 1800w,\n/static/edb8780061220c2f05fff8f22dc99ade/97a96/dam.png 2400w,\n/static/edb8780061220c2f05fff8f22dc99ade/25b59/dam.png 2960w\"\n        sizes=\"(max-width: 1200px) 100vw, 1200px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"eager\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(Caption, null, "All 24 possible pairing solutions for 4 students and 4 tutors, assuming everyone can be matched. The problem for DAM has another layer of complexity: We must select a pairing that most respect the preferences of the students and tutors."))), "\n", React.createElement(_components.p, null, "We built a script to automatically find pairings. Each student fills an online form indicating its weak/strong subjects, availability, and school. For tutors, they specify subjects they are comfortable with, their availability, and the schools they can commute to."), "\n", React.createElement(Citation, null, "The solver maximizes the number of pairings between students and tutors while optimizing on the people's preferences.”"), "\n", React.createElement(_components.p, null, "We then use OR-tools by Google, specifically the ", React.createElement(_components.a, {
    href: "https://developers.google.com/optimization/cp/cp_solver"
  }, "CP-SAT solver"), ", to find an optimized pairing. We first define the hard constraints: A student and a tutor can only be matched if:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "They share at least 1 hour availability"), "\n", React.createElement(_components.li, null, "The student's school is part of the preferred tutor's schools."), "\n", React.createElement(_components.li, null, "The tutor and the student are not yet matched (equivalent to impose a maximum of one tutor/student per student/tutor)"), "\n"), "\n", React.createElement(_components.p, null, "Then, we specify the soft constraints to maximize on:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pair the most number of students."), "\n", React.createElement(_components.li, null, "Pair the students most needed subjects with the tutors preferred subjects."), "\n", React.createElement(_components.li, null, "Assign most tutors to their preferred schools."), "\n"), "\n", React.createElement(_components.p, null, "It results that the solver maximizes the number of pairings between students and tutors while optimizing on the people’s preferences. After multiple testing on historical and synthetic data, we were convinced that the program can be used on the autumn cohort. And it proved to be right, it took only few minutes between the end of the registration and getting the matching back."), "\n", React.createElement(Citation, {
    author: "Félix Filiatreault",
    role: "Assistant director at Le Diplôme Avant la Médaille"
  }, "It works very nicely! Even better than expected! You can't imagine how much we like it and how much time it saved us!"), "\n", React.createElement(_components.p, null, "We'll also call present in the future to help them add new features. For instance, the pandemic forces them to set up online tutoring. It is a small adjustment to the script, but it makes a huge difference as the program can continue through social distancing."), "\n", React.createElement(_components.p, null, "Le Diplome avant la médaille was facing an important challenge for expanding their tutoring offer. We helped them deal with it by providing a custom-build solution. We are proud to have volunteered to solve these practical engineering tasks while they focus on helping students."), "\n", React.createElement(Meta, null, React.createElement(MetaField, {
    title: "author"
  }, "Edward Laurence"), "\n", React.createElement(MetaField, {
    title: "info"
  }, "Check and support DAM's program ", React.createElement(_components.a, {
    href: "https://diplomeavantlamedaille.org"
  }, "here"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
